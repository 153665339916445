import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import {
  bool,
  instanceOf,
  number,
  string,
} from 'prop-types';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import { normalizePath } from '../../utils/get-url-path';
import Box from './Box';
import PaginateGrid from './PaginateGrid';
import SectionHeader from '../SectionHeader';
import setPageTitleAndFavicon from '../../utils/setPageTitleAndFavicon';

export default function ArticleGrid(
  {
    paginate,
    pageContext,
    listTitle,
    maxColumns,
    posts,
    route,
    hidePageTitle,
  },
) {
  setPageTitleAndFavicon(`${listTitle} - Senior Savings`);
  const postImage = (post) => (
    <Box>
      {!!post?.featuredImage?.node?.localFile?.childImageSharp && (
        <Img
          className="articleImage"
          fluid={
            post.featuredImage.node.localFile.childImageSharp.fluid
          }
        />
      )}
    </Box>
  );

  const postText = (post) => (
    <Box>
      <Typography className="titleLink" variant="h2">
        {post.title}
      </Typography>
    </Box>
  );

  return (

    <Grid container justify="flex-start" alignItems="flex-start">
      {!hidePageTitle && <SectionHeader title={listTitle} />}
      {posts.map((post) => (
        <Grid className="articleGridContainer" item xs="11" sm="6" md={maxColumns}>
          <Box key={post.link}>
            <Link className="articleGrid" to={normalizePath(post.uri)}>
              { post.categories && (
                <div className="articleGridCategory">
                  {post.categories.nodes[0].name}
                </div>
              )}
              {postImage(post)}
              {postText(post)}
            </Link>
          </Box>
        </Grid>
      ))}
      {paginate && <PaginateGrid route={route} pageContext={pageContext} listTitle={listTitle} />}
    </Grid>
  );
}

ArticleGrid.propTypes = {
  route: string.isRequired,
  listTitle: string.isRequired,
  maxColumns: number,
  posts: instanceOf(Object).isRequired,
  pageContext: instanceOf(Object).isRequired,
  paginate: bool,
  hidePageTitle: bool,
};

ArticleGrid.defaultProps = {
  maxColumns: 3,
  paginate: false,
  hidePageTitle: false,
};
