import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { string } from 'prop-types';

export default function SectionHeader({ title }) {
  return (
    <Grid container justify="flex-start" alignItems="flex-start">
      <div id="back-to-top-anchor" />
      <Typography className="listTitle">
        {title}
        <hr className="horizontalLine" />
      </Typography>
    </Grid>
  );
}

SectionHeader.propTypes = {
  title: string.isRequired,
};
