import React from 'react';
import { instanceOf, string } from 'prop-types';
import Pagination from '@material-ui/lab/Pagination';
import { navigate } from 'gatsby';
import Box from './Box';

export default function PaginateGrid({ route, pageContext, listTitle }) {
  const changePages = (event, value) => {
    const path = value === 1 ? `/${route}/${listTitle.toLowerCase()}/` : `/${route}/${listTitle.toLowerCase()}/page/${value}/`;
    return navigate(path);
  };
  return pageContext && pageContext.totalPages > 1 && (
    <Box mt={10}>
      <Pagination count={pageContext.totalPages} page={pageContext.page} onChange={changePages} />
    </Box>
  );
}

PaginateGrid.propTypes = {
  pageContext: instanceOf(Object).isRequired,
  listTitle: string.isRequired,
  route: string.isRequired,
};
